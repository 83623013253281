@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
body {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to left, rgb(27 20 41), rgb(20 15 35));
  height: 100vh; /* Define a altura do corpo como 100% da altura da viewport */
  background-size: cover; /* Garante que a imagem de fundo cubra toda a área */
  background-position: center; /* Centraliza a imagem de fundo */
}
